import React from 'react';
import { Button, Dimmer, Loader } from 'semantic-ui-react';
import GConf from './generalConf';
import axios from 'axios';
import { toast } from 'react-toastify';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.state = { sendingReport: false };
  }
  
  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    console.error('Error caught by ErrorBoundary: ', error, errorInfo);
  }

  sendMessage(){
    // const phoneNumber = '2169678767'; // Replace with your desired phone number
    // const message = 'Hello'; // The message you want to send
    // const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    
    // window.open(whatsappUrl, '_blank'); // Open WhatsApp Web with the message pre-filled

      this.setState({ sendingReport: true });
      axios.post(`${GConf.SharedApi}/report-bug`, {
        PID: GConf.PID,
        SystemTag: GConf.systemTag,
        url: window.location.href
      })
      .then((response) => {  // Use an arrow function to keep `this` context
        this.setState({ sendingReport: false });
        toast.success("Probleme Signalé !", GConf.TostSuucessGonf)
      })
      .catch((error) => {
        this.setState({ sendingReport: false }); // Optionally handle errors
      });
  };
  ReInstalApp(){
    // Clear localStorage
    localStorage.clear();

    // Clear sessionStorage
    sessionStorage.clear();

    // If using service workers, unregister them
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let registration of registrations) {
                registration.unregister();
            }
        });
        caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
                caches.delete(cacheName);
            });
        });
    }

    // Reload the page
    //window.location.reload(true);
    window.location.href = '/'
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (<div className='text-center ' style={{display: 'flex', justifyContent:'center', alignItems:'center',   height: '100vh'}}>  
                    <Dimmer active={this.state.sendingReport}>
                      <Loader>En court ...</Loader>
                    </Dimmer>
                    <div className='row container'>
                      <div className='col-12 col-lg-8 '>
                          <h1 className='text-danger text-start'>Quelque chose s'est mal passé.</h1>
                          <p className='text-secondary text-start'>Vous pouvez résoudre le problème avec ces trois solutions : </p>
                          <div className='text-start'>
                               
                              <Button className='rounded-pill mb-2' onClick={() => window.location.href = '/'} >1- rafraîchir la page </Button>
                              <br />
                              <Button className='rounded-pill mb-2' onClick={() => this.sendMessage()}>2- Signaler le problème </Button>
                              <br />
                              <Button className='rounded-pill mb-2' onClick={() => this.ReInstalApp()}>3- ReInstaller Le Systeme  </Button>
                          </div>
                      </div>
                      <div className='col-12 col-lg-4 align-self-center'><img src='https://cdn.abyedh.com/Images/system/confused.png' className='img-responsive' width='150px' /></div>
                    </div>
                    
                    
            </div>);
    }

    return this.props.children; 
    
  }
}

export default ErrorBoundary;
