import React, { useEffect, useRef, useState } from 'react';
import { Bounce } from 'react-reveal';
import { Button, Form, Label, Segment, TextArea } from 'semantic-ui-react'
import { NavLink } from "react-router-dom";
import axios from 'axios';
import GConf from '../../../AssetsM/generalConf';
import { toast } from 'react-toastify';
import SKLT from '../../../AssetsM/Cards/usedSlk';
import { useTranslation, Trans } from 'react-i18next';

const SendBox = ({SendMessage, setMesgC,msgContent}) =>{
    const { t, i18n } = useTranslation();
    return(<>
             <div className='row fixed-bottom' style={{left: '45%',  bottom:0, width:'55%' }}>
                <div className='col-10 align-self-center'>
                <Form>
                    <TextArea placeholder='Ajouter Notes ici' value={msgContent} className="mb-2 border-div" rows='1' onChange={ (e) => setMesgC(e.target.value)}></TextArea>
                </Form>
                </div>
                <div className='col-2 align-self-center '><Button  icon='send'  className='rounded-circle mb-2' onClick={SendMessage}></Button></div>
            </div>
        </>)
}

function MessagesPages() {
    const { t, i18n } = useTranslation();
    const messagesEndRef = useRef(20)
    const [messagesList, setMessageList] = useState([])
    const [messagesSelected, setMessagSelected] = useState([])
    const [selectedConversation, setSelectedConversation] = useState({})
    const [msgContent, setMesgC] = useState('')
    const [updateS, setUpdateS] = useState()
    const [loading , setLoading] = useState(false)
    const convDetail = [
        {id: 1, genre:'SYSTEM', message:'Bonjour houssem', time:'10:12'},
        {id: 2, genre:'CAMION', message:'Bonjour houssem', time:'10:12'},
        {id: 2, genre:'CAMION', message:'Bonjour houssem', time:'10:12'},
        {id: 2, genre:'SYSTEM', message:'Bonjour houssem', time:'10:12'},
        {id: 2, genre:'CAMION', message:'Bonjour houssem', time:'10:12'},
        {id: 2, genre:'SYSTEM', message:'Bonjour houssem', time:'10:12'},
        {id: 2, genre:'CAMION', message:'Bonjour houssem', time:'10:12'},
        {id: 2, genre:'SYSTEM', message:'Bonjour houssem', time:'10:12'},
        {id: 2, genre:'CAMION', message:'Bonjour houssem', time:'10:12'},
        {id: 2, genre:'SYSTEM', message:'Bonjour houssem', time:'10:12'},
        {id: 2, genre:'SYSTEM', message:'Bonjour houssem', time:'10:12'},
        {id: 2, genre:'CAMION', message:'Bonjour houssem', time:'10:12'},
        {id: 2, genre:'SYSTEM', message:'Bonjour houssem', time:'10:12'},
    ]

    //use Effect
    useEffect(() => {
        axios.post(`${GConf.SharedApi}/messagesListe`, {
            PID: GConf.PID,
            SystemTag :  'docteur_rdv'
             
        })
        .then(function (response) {
            setMessageList(response.data)
            SelectConversation(response.data[0])
            console.log('time : '+ new Date().toLocaleTimeString())
            setLoading(true)
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
        })
        
    }, [updateS])
    
    //Function
    const SelectConversation = (ConID) => {
        setSelectedConversation(ConID)
        axios.post(`${GConf.SharedApi}/message`, {
            MID :  ConID.R_ID  
        })
        .then(function (response) {
            setMessagSelected(response.data)
            setLoading(true)
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
        })
    }
    const SendMessage = () =>{
        if (!msgContent) {toast.error("Message Vide !", GConf.TostErrorGonf)}
        else{
            axios.post(`${GConf.ApiLink}/message/ajouter`, {
                tag: GConf.SystemTag,
                msgC: msgContent,
            })
            .then(function (response) {
                if(response.data.affectedRows) {
                    setUpdateS(Math.random() * 10);
                    setMesgC('')
                    toast.success("Envoyer", GConf.TostSuucessGonf)
                   
                    console.log(response.data)
                }
                else{
                    toast.error('Erreur', GConf.TostSuucessGonf)
                    console.log(response.data)
                }
            })

        }
    }
    const SentMessage = (props) => {
        const  capitalizeFirstLetter = (string) =>{
            return (string.charAt(0).toUpperCase() + string.slice(1)).charAt(0);
        }

        return(<>
                <div className="row">
                    <div className='col-1 align-self-center text-end text-secondary'><small>{props.content.Sent_Time.slice(0, 5)}</small></div>
                    <div className='col-11'>
                        <div className="d-flex ">
                            <div className="flex-grow-1 me-1">
                               <div className='text-end'><small className='text-secondary'>{new Date(props.content.Sent_Date).toLocaleDateString('en-US')}</small></div>
                                <div className='card p-2 rounded-message-s mb-3 ps-4 pe-2 border-0 text-white text-end' style={{backgroundColor:'#0084ff'}}  >{props.content.Content}</div>
                            </div>
                            <div className="flex-shrink-0 ">
                                <Label size='medium' className='rounded-circle'  color='grey' key={1}>
                                    <h4>{capitalizeFirstLetter(GConf.ProfileData.Name)}</h4>
                                </Label>
                            </div>
                        </div>
                    </div>
                </div>     
            </>)
    }
    const RecivedMessage = (props) => {
        return(<>
                <div className="row">
                    <div className='col-10'>
                        <div className="d-flex ">
                                <div className="flex-shrink-0 ">
                                    <img src={`https://cdn.abyedh.com/images/p_pic/${selectedConversation.PictureId}.gif`} className='rounded-circle' width='40px' height='40px' />
                                </div>
                                <div className="flex-grow-1 ms-1">
                                    <div className='text-start'><small className='text-secondary '>{selectedConversation.Name} ( {new Date(props.content.Sent_Date).toLocaleDateString('en-US')} )</small></div>
                                    <div className='card p-2 rounded-message-r  mb-3 ps-2 pe-4 border-0 text-dark button-dark' style={{backgroundColor:'#f0f0f0'}}>{props.content.Content}</div>
                                </div>
                        </div>
                        
                    </div>
                    <div className='col-2 align-self-center text-start text-secondary'><small>{props.content.Sent_Time.slice(0, 5)}</small></div>
                </div> 
                
            </>)
    }
    const MessagesDetails = () =>{

         return(
                messagesSelected.map( (convMsg) => convMsg.PID == '0' ? <RecivedMessage content={convMsg} /> : <SentMessage content={convMsg} />  )
                 )

    }
    const ConversationCard = (props) => {
        return(<>
        <div className={`border-bottom  p-2 mb-2 ${props.data.R_ID == selectedConversation.R_ID ? 'bg-gray':''} `} onClick={() => SelectConversation(props.data)} >
            <div className='row'>
                <div className='col-2 align-self-center'><img src={`https://cdn.abyedh.com/images/p_pic/${props.data.PictureId}.gif`} className='rounded-circle' width='40px' height='40px' /></div>
                <div className='col-10 align-self-center'>
                    {props.data.Name}
                    <div>{props.data.R_Date}</div>
                </div>
            </div>
                
        </div>
        </>)
    }
    
    return (<>
        
            <h5><span className="bi bi-chat-left-text-fill"></span> {t('communUsed.messagePage.titleName')} </h5>
            <br />
                {
                    messagesList.length == 0 ? 
                    <>
                        <h3 className='text-center'> {t('communUsed.messagePage.alertMessage')} </h3>
                        <img src='https://cdn.abyedh.com/Images/system/no-message.svg' className='img-responsive' width='80%'  height='250px' />
                    </>
                    :

                    <div className="row p-0">
                        <div className="col-12 col-lg-4  border-rigth">  
                                {messagesList.map((data,index) => <ConversationCard key={index} data={data} /> ) }
                        </div>
                        <div className="col-12 col-lg-8 text-center">
                            {
                                messagesSelected.length == 0 ?
                                <>
                                    <h3 className='text-center'> Selectionner une conversations  </h3>
                                    <span className='bi bi-chat bi-lg'></span>
                                </>
                                :
                                <div>
                                    <div className='shadow-sm border-div border p-2' style={{height:'400px', overflowX:'auto', overflowX:'hidden', }}>
                                        {loading ?   <MessagesDetails />  : SKLT.CardList }
                                        <div ref={messagesEndRef} />
                                    </div> 

                                    <SendBox SendMessage={SendMessage} setMesgC={setMesgC} msgContent={msgContent}/> 
                                </div>
                            }
                            
                        </div>
                    </div>
                }
    </>);
}

export default MessagesPages;