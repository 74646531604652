const HindiTrans = {
  translation: {
    menusAndTabsName: {
      leftBar: {
        profile: "प्रोफ़ाइल",
        parametre: "पैरामीटर",
        forum: "फोरम",
        message: "संदेश",
        sauvgarder: "सहेजें",
        syncro: "समकक्षीकरण",
        documentation: "प्रलेखन",
        deconextion: "लॉगआउट",
      },
      topBar: {
        ma: "मुख्य पृष्ठ",
        rq: "सदस्यता",
        ab: "सदस्यता",
        sa: "सत्र",
        eq: "उपकरण",
        mb: "सदस्य",
        tm: "समूह",
      },
      alternativeLink: {
        1: {
          title: "सहायक",
          descrip: "सहायक के लिए इंटरफ़ेस"
          },
        2: {
          title: "कैलेंडर",
          descrip: "कैलेंडर के लिए अंतराफलक",
        },
      },
    },
    communUsed: {
      logInPage: {
        connectionTitle: "कनेक्शन:",
        identifiant: "पहचान",
        pwd: "पासवर्ड",
        logInBtn: "लॉग इन",
        inscrireLink: "पंजीकरण करें",
        downloadBtn: "अपने पीसी पर सिस्टम डाउनलोड करें",
        toast: {
          addIdentif: "पहचान दर्ज करें!",
          addPwd: "पासवर्ड दर्ज करें!",
          successLog: "लॉग इन किया गया!",
          notfound: "त्रुटि, कृपया पुनः प्रयास करें!",
        },
      },
      profilePage: {
        mainTitle: "प्रोफ़ाइल",
        numAvis: "समीक्षाएँ",
        numLikes: "पसंद",
        profileLinkBtn: "प्रोफ़ाइल",
        menuTabList: {
          generalEdit: "संपादित करें",
          pwdEdit: "पासवर्ड",
          horaireEdit: "समय सारणी",
          positionEdit: "स्थिति",
          imagesEdit: "छवियाँ",
          avisShow: "समीक्षाएँ",
          printPromote: "प्रिंट करें",
        },
        generalEditData: {
          title: "सामान्य जानकारी",
          name: "नाम और पहचान",
          phone: "फ़ोन",
          location: "जगह",
          gouv: "क्षेत्र का चयन करें",
          deleg: "विभाग का चयन करें",
          adress: "पता",
          genre: "लिंग",
          editBtn: "संपादित करें",
        },
        pwdEditData: {
          title: "पासवर्ड परिवर्तन",
          identifiant: "पहचान",
          pwd: "पासवर्ड",
          editBtn: "संपादित करें",
        },
        horaireEditData: {},
        positionEditData: {},
        imagesEditData: {
          noPhoto: "आपके पास कोई तस्वीरें नहीं हैं",
          clicToAdd: "छवियाँ अपलोड करने के लिए यहाँ क्लिक करें",
        },
        avisShowData: {},
        printPromoteData: {},
      },
      settingPage: {
        title: "सामान्य सेटिंग्स",
        activationCard: {
          title: "सक्रियण",
          jour: "दिन",
          isActivated: "सक्रिय है",
          isExpired: "समाप्त हो गया है",
          activeIci: "अपना सिस्टम यहाँ सक्रिय करें",
          activeIciBtn: "सक्रिय करें",
        },
        confirmerCard: {
          title: "पुष्टि",
          isConfirmer: "पुष्टि हो गई है",
          isConfirmerText: "आपका खाता abyedh.com पर पुष्टि की गई है",
          nonConfirmer: "पुष्टि नहीं हुई",
          nonConfirmerText: "आपका खाता abyedh.com पर पुष्टि नहीं हुआ है",
          nonConfirmerBtn: "पुष्टि करें",
        },
        settingCard: {
          1: {
            title: "सदस्यता",
            desc: "सदस्यता, अधिकतम संख्या, स्वचालित सदस्यता",
          },
          2: {
            title: "सत्र",
            desc: "सत्र, समय सारणी, सदस्य",
          },
          3: {
            title: "सामग्री",
            desc: "आइटम, संपादित करें, इन-आउट करें",
          },
          4: {
            title: "सदस्यता",
            desc: "संशोधन, हटाना, क्रेडिट",
          },
          5: {
            title: "सदस्य",
            desc: "नए, संपादित करें, वफादारी",
          },
          6: {
            title: "टीम",
            desc: "नए, उपस्थिति, प्राथमिक",
          },
        },
      },
      paymmentPage: {
        montant: "500",
        currency: "₹",
        montantAnnuel: "वार्षिक पंजीकरण राशि:",
        textOne:
          "भुगतान Moneygram सेवा के माध्यम से एक स्थानांतरण द्वारा किया जाता है",
        textTwo: " मालिक के पास",
        textThree: "टेलीफोन",
        afterFinishAlert:
          "ट्रांसफर पूरा करने के बाद, आपको यहाँ जानकारी दर्ज करनी होगी:",
        nomText: "भुगतान भेजने के लिए नाम",
        nomPlchText: "नाम",
        montantText: "राशि",
        montantPlchText: "राशि",
        codeText: "भुगतान कोड",
        codePlchText: "कोड",
        phoneText: "फ़ोन",
        phonePlchText: "फ़ोन",
        posteText: "डाक दफ्तर",
        postePlchText: "डाक दफ्तर",
        postalCodeText: "पिन कोड",
        postalCodePlchText: "पिन कोड",
        saveBtn: "सहेजें",
      },
      forumPage: {
        inoutPlch: "यहाँ स्थिति जोड़ें",
      },
      messagePage: {
        titleName: "संदेश",
        alertMessage: "आपके पास कोई संदेश नहीं हैं",
      },
      savgarderPage: {
        selectItemsCard: {
          title: "अपने डेटा को अपने कंप्यूटर पर सहेजें",
          itemsList: {
            1: "नियुक्तियाँ सहेजें",
            2: "ऑफ़र सहेजें",
            3: "सत्र सहेजें",
            4: "रिपोर्ट सहेजें",
            5: "रोगी सहेजें",
            6: "टीम सहेजें",
            7: "पर्च े सहेजें",
          },
          exportBtn: "निर्यात",
          saveCopy: "कॉपी डाउनलोड करें",
        },
        netoyerCard: {
          title: "सर्वर पर अनचाहे फ़ाइलों को साफ करें",
          notoyerText: "फ़ाइलों का कुल आयात जोखिम है:",
          netoyerBtn: "सर्वर को साफ करें",
        },
      },
      sysncroPage: {
        title: "तालिकाएँ अद्यतित करें",
        savingModal: "सहेज रहा है",
        mainCard: {
          topText: "अपने डेटा को अद्यतित करने के लिए बटन पर क्लिक करें",
          bottomText:
            "जब आप इंटरनेट सर्वर पर डेटा अपडेट करते हैं, तो आप अपने ऐप का उपयोग ऑफ़लाइन मोड में भी कर सकते हैं। हमेशा अद्यतित रहें",
          btnText: "अद्यतन करें",
        },
        itemsList: {
          1: "नियुक्तियाँ",
          2: "सत्र",
          3: "रिपोर्ट",
          4: "रोगी",
          5: "टीम",
          6: "पर्चे",
        },
      },
      documentPage: {
        itemsList: {
          1: "नियुक्तियाँ",
          2: "सत्र",
          3: "रिपोर्ट",
          4: "रोगी",
          5: "टीम",
          6: "पर्चे",
        },
        savModal: {
          title: "सेवा बाद में",
          inputPlch: "अपनी समस्या यहाँ पूछें",
        },
      },
    },
    menuTabs: {
      mainPage: {
        LinksCardItems: {
          rapportNum: {
            title: "रिपोर्ट",
            desc: "नया",
          },
          seanceNum: {
            title: "सत्र",
            desc: "नया",
          },
          patientNum: {
            title: "रोगी",
            desc: "जोड़ें",
          },
          ordonanceNum: {
            title: "पर्चा",
            desc: "नया",
          },
          equipeNum: {
            title: "टीम",
            desc: "मौजूद",
          },
        },
        evolutionCard: {
          title: "सत्र के विकास",
        },
        tabsCard: {
          seance: {
            tabName: "सत्र",
            cardTitle: "सत्रों का वितरण",
          },
          rdv: {
            tabName: "मिलने का समय",
            cardTitle: "मिलने का समय का वितरण",
          },
          patient: {
            tabName: "रोगी",
            cardTitle: "रोगियों का वितरण",
          },
        },
      },
      rdvPage: {
        calendarLink: "कैलेंडर",
        tabsTtems: {
          attent: "ध्यान",
          seen: "देखा गया",
          accepte: "स्वीकृत",
          refuse: "अस्वीकार",
          retarde: "देरी",
          redirecte: "पुनर्निर्देशित",
          termine: "समाप्त",
        },
        infoBtn: "जानकारी",
        tableHeaderItems:
          "'*','ID','रोगी', 'गुजरा हुआ','आयाम','समय','स्थिति','रिकॉर्ड किया गया?','देखें'",
        calendarCardData: {
          searchCardTitle: "खोज...",
          searchCardBtn: "खोजें",
          rdvToday: "आज का रेंडीवू",
        },
        rdvInfoCardData: {
          title: "रेंडीवू",
          controlBtnCard: {
            title: "नियंत्रण",
            rejectBtn: "रद्द करें",
            acceptBtn: "स्वीकार करें",
            retardBtn: "विलंबित करें",
            redirectBtn: "पुनर्निर्देशित करें",
            saveBtn: "सत्र सहेजें",
            termineBtn: "समाप्त करें",
            nonSeenBtn: "अदृश्य के रूप में चिह्नित करें",
          },
          infoReqCard: {
            title: "रेंडीवू जानकारी",
            nameOfUser: "नाम और पहचान",
            wantedDate: "चाहिए गई तारीख",
            wantedTime: "चाहिए गई समय",
            reqDay: "गुजरा हुआ दिन",
            comment: "टिप्पणी",
          },
          infoUserCard: {
            title: "रोगी जानकारी",
            wantYouSaveThisUser: "",
            name: "नाम:",
            phone: "फ़ोन:",
            delag: "प्रतिनिधि:",
            gouv: "सरकार:",
            adress: "पता:",
            saveBtn: "ग्राहक सहेजें",
            stateItem: {
              dejaEnreg: "पहले ही रिकॉर्ड किया गया",
              nouveaux: "नए रोगी",
            },
            statItemNames: {
              rdv: "रेंडीवू",
              ordonnace: "पर्चा",
              seances: "सत्र",
            },
            addUserCard: {
              title: "क्या आप इस रोगी को सहेजना चाहते हैं?",
            },
          },
        },
      },
      seancePage: {
        tarifName: "मूल्य",
        infoBtn: "जानकारी",
        maladieStateItems: {
          Bonne: "अच्छी स्थिति में",
          Malade: "बीमार",
          Reanimation: "पुनर्जीवन",
          Palliatifs: "पैलिएटिव केयर में",
          Quarantaine: "क्वारंटीन",
          Observation: "अवलोकन में",
        },
        seanceInfo: {
          tabsName: {
            diagnostique: "निदान",
            analyse: "विश्लेषण",
            ordonance: "आदेश",
            info: "जानकारी",
          },
          analyseList: "विश्लेषण सूची",
          ordonanceLit: " दवाओं की सूची",
          ordonanceTable: {
            No: "नंबर",
            Designiation: "नाम",
            Dosage: "मात्रा",
            Forme: "फॉर्म",
            Presentation: "प्रस्तुति",
          },
          infoTable: {
            date: "तारीख और समय",
            maladie: "बीमारी",
            degree: "बीमारी का स्तर",
            description: "बीमारी का विवरण",
          },
          controlBtnCard: {
            title: "नियंत्रण",
            modifier: "संशोधित करें",
            supprimer: " हटाएं",
            imprimer: "आदेश छापें",
            suppModal: {
              voulezVous: "क्या आप वाकई इस सत्र को हटाना चाहते हैं?",
              suppBtn: "हटाएं",
            },
          },
        },
        addSeanceInfo: {
          tabsName: {
            selectPat: "रोगी का चयन करें",
            diagnostique: "निदान दर्ज करें",
            analyse: "विश्लेषण करें",
            ordonance: "आदेश लिखें",
            terminer: "परिणाम समाप्त करें",
          },
          selectTabsData: {
            patientCardTitle: "रोगी",
            btnsText: {
              patientUID: "रोगी UID",
              scanRDV: "मीटिंग स्कैन करें",
            },
            patientInfo: {
              Nom: "नाम",
              phone: "फोन",
              adress: "पता ",
              nbSeance: "सत्रों की संख्या",
              etatSnit: "स्वास्थ्य स्थिति:",
            },
            leftTabs: {
              seance: "सत्र ",
              rdv: "मीटिंग",
              ordonance: "आदेश",
              tableHearder: "'*','आईडी ','तारीख','समय','जानकारी'",
            },
          },
          diagnosqtiqueTabsData: {
            title: "निदान",
          },
          analyseTabsData: {
            title: "आयाम जोड़ें",
            grandeurPlch: "आयाम",
            valeurPlch: "मान",
            btnText: "जोड़ें",
            listeText: "विश्लेषण सूची",
          },
          ordonanceTabsData: {
            title: "आइटम जोड़ें",
            addPlch: "दवा डालें",
            code: "बारकोड :",
            Nom: "नाम :",
            Dosage: "मात्रा :",
            Forme: "फॉर्म :",
            Presentation: "प्रस्तुति :",
            modePlch: "प्रयोग का तरीका",
            btnText: "जोड़ें",
            listeText: "विश्लेषण सूची",
          },
          terminerTabsData: {
            maladie: " बीमारी",
            resultat: "परिणाम: बीमारी",
            resultatPlch: "बीमारी",
            date: "तारीख ",
            degre: "खतरे का स्तर",
            genre: "सत्र का प्रकार",
            pasGenre: {
              one: "कोई प्रकार संचित नहीं",
              two: "यहाँ क्लिक करें",
              three: "जोड़ने के लिए ",
            },
            saveBtn: "सहेजें",
            printBtn: "आदेश छापें",
          },
        },
        editSeance: {
          editBtn: "संपादित करें",
          pasDordo: "कोई आदेश नहीं",
          editOrdonance: "आप यहाँ आदेश संपादित कर सकते हैं:",
        },
        resumerCard: {
          cardTitle: "अवधि दर्ज करें",
          rechercheBtn: "खोजें",
          tableHeader: "'*','रोगी','बीमारी','डिग्री','दिन','समय','देखें'",
        },
        tarifCard: {
          cardTitle: "मूल्य जोड़ें :",
          Nom: "नाम :",
          Description: "विवरण:",
          Tarif: "मूल्य:",
          addBtn: "सहेजें",
          infoBtn: "जानकारी",
        },
        tarifInfoCard: {
          prixText: "मूल्य",
          tarifText: "मूल्य",
          leftTab: {
            seance: "सत्र",
            modifier: "संशोधित करें",
          },
          seanceTableH: "'आईडी','सदस्य','तारीख','समय','देखें'",
          modifierCard: {
            nom: "नाम",
            tarif: "मूल्य",
            descr: "विवरण",
            modifierBtn: "संशोधित करें",
          },
        },
      },
      patientPage: {
        assuranceText: "आश्वासन",
        infoText: "जानकारी",
        addPatient: {
          nomEtPrenon: "नाम और पहचान",
          naissance: "जन्मतिथि",
          phoneNum: "फोन",
          location: "स्थान",
          gouv: "क्षेत्र चुनें",
          deleg: "विभाग चुनें",
          adresse: "पता",
          saveBtn: "सहेजें",
          rechercheAbyedh: {
            tabsNames: {
              enter: "दर्ज करें",
              scan: "स्कैन करें",
            },
            title: "आब्येद डेटाबेस में खोजें",
            btnText: "खोजें",
            clicToScan: "स्कैन करने के लिए क्लिक करें",
          },
        },
        patientInfo: {
          mainCardText: {
            verification: "सत्यापन",
            telephone: "फ़ोन",
          },
          TabsCardText: {
            seance: "सत्र",
            ordonance: "निर्देश",
            rdv: "अपॉइंटमेंट",
            modifier: "संपादित करें",
            verifier: "सत्यापित करें",
            supprimer: "हटाएं",
          },
          ordoTableaHeders: "'आईडी','ग्राहक','दिनांक','समय','देखें'",
          rdvTableaHeders: "'आईडी','ग्राहक','दिनांक','समय','कुल','देखें'",
          editCard: {
            nomEtPrenon: "नाम और पहचान:",
            naissance: "जन्मतिथि",
            phoneNum: "फोन",
            location: "स्थान",
            gouv: "क्षेत्र चुनें",
            deleg: "विभाग चुनें",
            adresse: "पता",
            editBtn: "संपादित करें",
          },
          verificationCard: {
            isVerifier: "यह रोगी सत्यापित है",
            searchInAbyedhDB: "आब्येद डेटाबेस में खोजें",
            nom: "नाम",
            phone: "फोन:",
            gouv: "क्षेत्र:",
            deleg: "विभाग:",
            verifBtn: "सत्यापित करें",
            searchBtn: "खोजें",
          },
          deleteCard: {
            mainTitle: "क्या आप वाकई इस रोगी को हटाना चाहते हैं?",
            alertText: "जब आप एक रोगी को हटाते हैं",
            conscOne: "रोगी 'रोगी' शाखा में दिखाई नहीं देगा",
            conscTwo: "इस रोगी से संबंधित सभी डेटा क्षतिग्रस्त हो सकता है",
            conscThree: "इस रोगी के साथ और कोई बिल या आदेश नहीं बना सकते",
            deleteBtn: "हाँ, हटाएं",
          },
        },
      },
      ordonancePage: {
        StockText: "स्टॉक",
        InfoBtn: "जानकारी",
        ordoState: {
          terminer: "समाप्त",
          annule: "रद्द",
          enCours: "प्रगति में",
          indefinie: "अनिर्दिष्ट",
        },
        addOrdoPage: {
          listeText: "विश्लेषण सूची",
          tabsText: {
            enter: "दर्ज करें",
            dateClient: "तारीख और ग्राहक",
            save: "सहेजें",
          },
          enterCard: {
            title: "आइटम जोड़ें",
            addPlch: "दवा दर्ज करें",
            code: "बारकोड:",
            Nom: "नाम:",
            Dosage: "खुराक:",
            Forme: "फॉर्म:",
            Presentation: "प्रस्तुति:",
            modePlch: "उपयोग का तरीका",
            btnText: "जोड़ें",
          },
          dateClientCard: {
            title: "तारीख और ग्राहक",
            nomClientText: "नाम:",
            adresseClientText: "पता:",
          },
          finisCard: {
            titleText: "बटन्स",
            saveBtn: "सहेजें",
            printBnt: "प्रिंट करें",
          },
        },
        ordoInfoPage: {
          titleText: "पर्चा",
          ordoId: "पर्चा आईडी:",
          ordoCode: "पर्चा कोड:",
          patientText: "रोगी:",
          dateText: "तारीख:",
          TimeText: "समय:",
          tableHeader: {
            No: "नंबर",
            Designiation: "डिज़ाइनेशन",
            Dosage: "खुराक",
            Forme: "फॉर्म",
            Presentation: "प्रस्तुति",
          },
          controlBtns: {
            title: "नियंत्रण",
            printBtn: "प्रिंट",
            editBtn: "संपादित करें",
            deleteBtn: "हटाएं",
            seeSeance: "सत्र देखें",
          },
        },
        modifierOrdoPage: {
          editBtn: "संपादित करें",
        },
        stockCard: {
          addCard: {
            title: "नई दवाइयाँ जोड़ें",
            enregistrerBtn: "सहेजें",
            fermerBtn: "बंद करें",
          },
          infoCard: {
            tabsText: {
              info: "जानकारी",
              modifier: "संपादित करें",
              supprimer: "हटाएं",
            },
            cardData: {
              dosage: "खुराक",
              presentaion: "प्रस्तुति",
            },
            alertTer:
              "आप इस दवा को संपादित या हटा नहीं सकते, क्योंकि यह एक सार्वजनिक दवा है।",
            deleteCard: {
              mainTitle: "क्या आप वाकई इस दवा को हटाना चाहते हैं?",
              alertText: "दवा को हटाने पर",
              conscOne: 'दवा "दवाओं" शाखा में दिखाई नहीं देगी।',
              conscTwo: "इस दवा से संबंधित सभी डेटा नुकसान पहुंचा सकते हैं।",
              conscThree: "आप इस दवा के साथ बिल या आदेश पास नहीं कर सकते।",
              deleteBtn: "हां, हटाएं",
            },
          },
        },
      },
      rapportPage: {
        infoBtn: "जानकारी",
        addRapport: {
          tabsText: {
            contenue: "सामग्री",
            terminer: "समाप्त",
          },
          rptPlch: "यहाँ अपनी रिपोर्ट लिखें |",
          terminerCard: {
            Titre: "शीर्षक",
            Sujet: "विषय",
            Date: "तारीख",
            Genre: "शैली",
            genreListe: {
              1: "सामान्य चिकित्सा",
              2: "अस्पताल में भर्ती",
              3: "आपातकालीन",
              4: "परामर्श",
              5: "मूल्यांकन",
              6: "नैदानिकी",
              7: "फ़ॉलो-अप",
              8: "चिकित्सा अनुसंधान",
              9: "क्लीनिकल केस",
              10: "मेडिकल-लीगल",
              11: "पोस्टमार्टम",
              12: "सार्वजनिक स्वास्थ्य",
              13: "अन्य",
            },
            saveBtn: "सहेजें",
            printBtn: "रिपोर्ट प्रिंट करें",
          },
        },
        rapportInfo: {
          titleText: "रिपोर्ट",
          rapportId: "रिपोर्ट आईडी : ",
          rapportTitre: "शीर्षक :",
          sujetText: "विषय : ",
          dateText: "तारीख :",
          GenreText: "शैली : ",
          controlCard: {
            title: "नियंत्रण",
            editBtn: "संपादित करें",
            printBtn: "प्रिंट करें",
            deleteBtn: "हटाएं",
          },
        },
        editRapport: {
          editBtn: "संपादित करें",
        },
      },
      teamPage: {
        interfaceLinkText: "आरडीवी इंटरफेस",
        infoBtn: "जानकारी",
        addTeamPage: {
          cin: "सीआईएन कार्ड:",
          nomEtPrenom: "नाम और पहचान:",
          phone: "फ़ोन:",
          poste: "पद:",
          adresse: "पता:",
          saveBtn: "सहेजें",
          rechercheAbyedh: {
            tabsNames: {
              enter: "दर्ज करें",
              scan: "स्कैन करें",
            },
            title: "एब्येद डेटाबेस में खोजें",
            btnText: "खोजें",
            clicToScan: "स्कैन करने के लिए क्लिक करें",
          },
        },
        addPoste: {
          emptyListeText: "दाईं ओर पद जोड़ें",
          currency: "डी.टी.",
          cardTitle: "पद जोड़ें",
          poste: "पद",
          salaire: "वेतन",
          experience: "अनुभव आवश्यक",
          saveBtn: "सहेजें",
          modifierTitle: "पद संपादित करें",
          modifierBtn: "संपादित करें",
          supprimerModal: {
            title: "परिवार हटाएं",
            confirmText: "क्या आप वाकई इस परिवार को हटाना चाहते हैं?",
            nom: "पद",
            desc: "विवरण",
            deleteBtn: "पद हटाएं",
          },
        },
        anavcePage: {
          selectMembre: "सदस्य चुनें",
          membrePlch: "सदस्य दर्ज करें",
          montant: "राशि",
          montantPlch: "मूल्य",
          addBtn: "जोड़ें",
          tableaHeader: "'*','नाम','दिन', 'मूल्य','एक्स'",
          deleteModal: {
            title: "अग्रिम हटाएं",
            confText: "क्या आप वाकई इस अग्रिम को हटाना चाहते हैं?",
            valeur: " मूल्य:",
            membre: " सदस्य:",
            confirmBtn: "हटाएं",
          },
        },
        presencePage: {
          selectMembre: "सदस्य चुनें",
          membrePlch: "सदस्य दर्ज करें",
          genre: "प्रकार चुनें",
          presence: "उपस्थिति",
          absance: "अनुपस्थिति",
          date: "तारीख",
          addBtn: "जोड़ें",
        },
        teamInfoPage: {
          mainCardText: {
            verification: "सत्यापन",
            telephone: "फ़ोन",
            notVerifier: "सत्यापित नहीं",
          },
          TabsCardText: {
            presence: "उपस्थिति",
            avance: "अग्रिम",
            caissePWD: "कैश एपडब्ल्यूडी",
            modifier: "संपादक",
            verifier: "सत्यापित करें",
            supprimer: "हटा दें",
          },
          presenceTableaHeders: "'ID','ग्राहक','दिन','समय','देखें'",
          avanceTableaHeders: "'ID','ग्राहक','दिन','समय','कुल','देखें'",
          editCard: {
            cin: "CIN कार्ड",
            nomEtPrenon: "नाम और पहचान:",
            phoneNum: "फ़ोन ",
            poste: "पद:",
            location: "स्थान ",
            gouv: "क्षेत्र चुनें",
            deleg: "विभाग चुनें",
            adresse: "पता",
            editBtn: "संपादित करें",
          },
          caissePWDData: {
            identifiant: "पहचान:",
            pwd: "पासवर्ड मोड:",
            smartID: "स्मार्ट आईडी ",
            editBtn: "संपादित करें",
          },
          verificationCard: {
            isVerifier: "यह सदस्य सत्यापित है",
            searchInAbyedhDB: "एब्येद डेटाबेस में खोजें",
            nom: "नाम",
            phone: "फ़ोन:",
            gouv: "राज्य:",
            deleg: "प्रतिनिधि:",
            verifBtn: "सत्यापित करें",
            searchBtn: "खोजें",
          },
          deleteCard: {
            mainTitle: "क्या आप वाकई इस सदस्य को हटाना चाहते हैं?",
            alertText: "सदस्य हटाने पर",
            conscOne: 'इस सदस्य को "सदस्य" शाखा में देखा नहीं जाएगा',
            conscTwo: "इस सदस्य से संबंधित सभी डेटा क्षतिग्रस्त हो सकता है",
            conscThree:
              "अन्यथा, आप इस सदस्य के साथ कोई भी चालान या आदेश नहीं कर सकते",
            deleteBtn: "हां, हटाएं",
          },
        },
      },
    },
    TableHead: {
      seance:
        "'*','आईडी','रोगी','बीमारी','गुण', 'दिन','समय','विश्लेषण','निर्देश','देखें'",
      seance:
        "'*','आईडी','रोगी','बीमारी','गुण', 'दिन','समय','विश्लेषण','निर्देश','देखें'",
      facture: "'*','आईडी','कैश','ग्राहक','दिन','समय','कुल','स्थिति','देखें'",
      ordonance: "'*','आईडी','रोगी','तारीख','समय','सत्र?','स्थिति','देखें'",
      seances: "'*','आईडी','कैश','ग्राहक','दिन','समय','स्थिति','देखें'",
      request: "'*','आईडी','ग्राहक', 'तारीख','तालिका','स्थिति','X','देखें'",
      reservation:
        "'*','आईडी','रोगी', 'बीत चुका','आयाम','समय','स्थिति','रिकॉर्डेड?','देखें'",
      menu: "'*','कोड', 'नाम', 'प्रकार','लागत','विक्रय मूल्य','X','देखें'",
      stock: "'*','कोड', 'नाम', 'प्रकार','कीमत','देखें'",
      camion: "'*','ट्रक','नंबर प्लेट', 'ड्राइवर','फंड','आय','X','देखें'",
      camionStock: "'कोड', 'नाम', 'प्रकार','स्टॉक','मूल्य','देखें'",
      camionFacture: "'आईडी','ग्राहक','दिन','कुल','देखें'",
      camionFond: "'आईडी','तारीख','कुल', 'SDF','SCF','देखें'",
      client: "'*','नाम','जन्म', 'फ़ोन', 'स्थान','पता','देखें'",
      clientCommande: "'आईडी', 'बीत चुका','आयाम','कुल','स्थिति','देखें'",
      clientFacture: "'आईडी','ग्राहक','दिन','कुल','देखें'",
      clientFactureC: "'आईडी','ग्राहक','दिन','कुल','देखें'",
      team: "'*','नाम','फ़ोन', 'सीआईएन','शुरू किया', 'पद','देखें'",
      medicammentPage:
        "'*','कोड','प्रकार','नाम', 'खुराक','फॉर्म', 'प्रस्तुतीकरण','कक्षा','देखें'",
      fournisseur: "'*','MF','नाम','फ़ोन', 'पता','प्रकार', 'दिन','देखें'",
    },
    BreadCrumb: {
      menuAddPlat: {
        1: "मेनू",
        2: "प्लेट जोड़ें",
      },
      menuFamille: {
        1: "मेनू",
        2: "परिवार",
      },
      platInfo: {
        1: "मेनू",
        2: "जानकारी",
      },
      forfraitInfo: {
        1: "शुल्क",
        2: "जानकारी",
      },
      stockAddArticle: {
        1: "स्टॉक",
        2: "आइटम जोड़ें",
      },
      stockFamille: {
        1: "स्टॉक",
        2: "परिवार",
      },
      stockBE: {
        1: "स्टॉक",
        2: "दाखिला बिल",
      },
      stockBS: {
        1: "स्टॉक",
        2: "निकासी बिल",
      },
      stockInfo: {
        1: "स्टॉक",
        2: "जानकारी",
      },
      factureAjouter: {
        1: "सत्र",
        2: "सत्र जोड़ें",
      },
      factureInfo: {
        1: "सत्र",
        2: "जानकारी",
      },
      factureEdit: {
        1: "रिपोर्ट",
        2: "संपादन",
      },
      factureResumer: {
        1: "सत्र",
        2: "सारांश",
      },
      CamionAdd: {
        1: "कैमियों",
        2: "कैमियों जोड़ें",
      },
      CamionAddFond: {
        1: "कैमियों",
        2: "फंड जोड़ें",
      },
      CamionFondInfo: {
        1: "कैमियों",
        2: "जानकारी",
        3: "फंड",
        4: "देखें",
      },
      CamionEditFond: {
        1: "कैमियों",
        2: "जानकारी",
        3: "फंड",
        4: "संपादित करें",
      },
      CamionInv: {
        1: "कैमियों",
        2: "कैमियों इन्वेंटरी",
      },
      CamionInfo: {
        1: "कैमियों",
        2: "जानकारी",
      },
      CamionArticleInfo: {
        1: "कैमियों",
        2: "जानकारी",
        3: "आइटम",
      },
      CamionFactureInfo: {
        1: "कैमियों",
        2: "जानकारी",
        3: "सत्र",
        4: "देखें",
      },
      ClientAdd: {
        1: "रोगी",
        2: "रोगी जोड़ें",
      },
      ClientInfo: {
        1: "ग्राहक",
        2: "जानकारी",
      },
      ClientMap: {
        1: "ग्राहक",
        2: "नक्शा",
      },
      ClientRegion: {
        1: "ग्राहक",
        2: "क्षेत्र",
      },
      RequestInfo: {
        1: "अपॉइंटमेंट",
        2: "जानकारी",
      },
      RequestCalendar: {
        1: "अपॉइंटमेंट",
        2: "कैलेंडर",
      },
      RequestCompte: {
        1: "आदेश",
        2: "खाते",
      },
      RequestReg: {
        1: "आदेश",
        2: "समूहीकरण",
      },
      FournisseurAdd: {
        1: "आपूर्तिकर्ता",
        2: "समूहीकरण",
      },
      FournisseurCalendar: {
        1: "आपूर्तिकर्ता",
        2: "समूहीकरण",
      },
      FournisseurSearch: {
        1: "आपूर्तिकर्ता",
        2: "समूहीकरण",
      },
      FournisseurInfo: {
        1: "आपूर्तिकर्ता",
        2: "समूहीकरण",
      },
      TeamAdd: {
        1: "टीम",
        2: "जोड़ें",
      },
      TeamInfo: {
        1: "टीम",
        2: "जानकारी",
      },
      TeamPoste: {
        1: "टीम",
        2: "पद",
      },
      TeamDemande: {
        1: "टीम",
        2: "नौकरी के लिए अनुरोध",
      },
    },
    SubNavs: {
      request: {
        1: "स्वीकृत",
        2: "अस्वीकृत",
        3: "अपूर्ण",
      },
      camion: {
        1: "ऑर्डर जोड़ें",
      },
      Menu: {
        1: "नया रिपोर्ट",
      },
      Stock: {
        1: "नया ऑर्डर",
        2: "फूड फैमिली",
        3: "इनवॉयस",
        4: "आउटवॉयस",
      },
      facture: {
        1: "नया सत्र",
        2: "सारांश",
      },
      client: {
        1: "नया रोगी",
      },
      Commande: {
        1: "खाते",
        2: "कैलेंडर",
      },
      Fournisseur: {
        1: "नया आपूर्तिकर्ता",
        2: "कैलेंडर",
        3: "खोज",
      },
      Equipe: {
        1: "नया सदस्य",
        2: "पद",
        3: "अग्रिम",
        4: "उपस्थिति",
      },
    },
  },
};
export default HindiTrans;
