const ItaliaTrans = {
  translation: {
    menusAndTabsName: {
      leftBar: {
        profile: "Profilo",
        parametre: "Parametri",
        forum: "Forum",
        message: "Messaggi",
        sauvgarder: "Salva",
        syncro: "Sincronizzazione",
        documentation: "Documentazione",
        deconextion: "Disconnessione",
      },
      topBar: {
        ma: "Homepage",
        rq: "Abbonamento",
        ab: "Abbonamento",
        sa: "Sessioni",
        eq: "Attrezzatura",
        mb: "Membro",
        tm: "Squadra",
      },
      alternativeLink: {
        1: {
          title: "Assistente",
          descrip: "Interfaccia per l'assistente"
          },
        2: {
          title: "Calendario",
          descrip: "Interfaccia per il calendario",
        },
      },
    },
    communUsed: {
      logInPage: {
        connectionTitle: "Connessione:",
        identifiant: "Identificazione",
        pwd: "Password",
        logInBtn: "Accedi",
        inscrireLink: "Iscriviti",
        downloadBtn: "Scarica il sistema sul tuo PC",
        toast: {
          addIdentif: "Inserisci un'identificazione!",
          addPwd: "Inserisci la password!",
          successLog: "Accesso effettuato!",
          notfound: "Errore, riprova!",
        },
      },
      profilePage: {
        mainTitle: "Profilo",
        numAvis: "Recensioni",
        numLikes: "Mi piace",
        profileLinkBtn: "Profilo",
        menuTabList: {
          generalEdit: "Modifica",
          pwdEdit: "Password",
          horaireEdit: "Orario",
          positionEdit: "Posizione",
          imagesEdit: "Immagini",
          avisShow: "Recensioni",
          printPromote: "Stampa",
        },
        generalEditData: {
          title: "Informazioni Generali",
          name: "Nome e Cognome",
          phone: "Telefono",
          location: "Posizione",
          gouv: "Seleziona una regione",
          deleg: "Seleziona un dipartimento",
          adress: "Indirizzo",
          genre: "Genere",
          editBtn: "Modifica",
        },
        pwdEditData: {
          title: "Modifica Password",
          identifiant: "Identificatore",
          pwd: "Password",
          editBtn: "Modifica",
        },
        horaireEditData: {},
        positionEditData: {},
        imagesEditData: {
          noPhoto: "Non hai immagini",
          clicToAdd: "Clicca per caricare le immagini",
        },
        avisShowData: {},
        printPromoteData: {},
      },
      settingPage: {
        title: "Impostazioni Generali",
        activationCard: {
          title: "Attivazione",
          jour: "Giorno",
          isActivated: "Attivato",
          isExpired: "Scaduto",
          activeIci: "Attiva il tuo sistema qui",
          activeIciBtn: "Attiva",
        },
        confirmerCard: {
          title: "Conferma",
          isConfirmer: "Confermato",
          isConfirmerText: "Il tuo account è confermato su abyedh.com",
          nonConfirmer: "Non Confermato",
          nonConfirmerText: "Il tuo account non è confermato su abyedh.com",
          nonConfirmerBtn: "Conferma",
        },
        settingCard: {
          1: {
            title: "Iscrizione",
            desc: "Iscrizione, Numero Massimo, Auto-Abbonamento ",
          },
          2: {
            title: "Sessioni",
            desc: "Sessioni, Orario, Membri",
          },
          3: {
            title: "Apparecchiature",
            desc: "Articoli, Modifica, Entrata/Uscita",
          },
          4: {
            title: "Abbonamento",
            desc: "Modifica, Elimina, Credito",
          },
          5: {
            title: "Membri",
            desc: "Nuovi, Modifica, Fedeltà",
          },
          6: {
            title: "Squadra",
            desc: "Nuovi, Presenza, Anticipo",
          },
        },
      },
      paymmentPage: {
        montant: "500",
        currency: "€",
        montantAnnuel: "Importo annuale di iscrizione:",
        textOne:
          "Il pagamento avviene tramite bonifico tramite il servizio Moneygram",
        textTwo: " al proprietario",
        textThree: "Telefono",
        afterFinishAlert:
          "Dopo aver completato il bonifico, devi inserire le informazioni qui:",
        nomText: "Il nome con cui inviare il pagamento",
        nomPlchText: "Nome",
        montantText: "Importo",
        montantPlchText: "Importo",
        codeText: "Codice pagamento",
        codePlchText: "Codice",
        phoneText: "Telefono",
        phonePlchText: "Telefono",
        posteText: "Uffici postali",
        postePlchText: "Uffici postali",
        postalCodeText: "Codice postale",
        postalCodePlchText: "Codice postale",
        saveBtn: "Salva",
      },
      forumPage: {
        inoutPlch: "Aggiungi stato qui",
      },
      messagePage: {
        titleName: "Messaggi",
        alertMessage: "Non hai messaggi",
      },
      savgarderPage: {
        selectItemsCard: {
          title: "Salva i tuoi dati sul tuo computer",
          itemsList: {
            1: "Salva gli Appuntamenti",
            2: "Salva le Offerte",
            3: "Salva le Sessioni",
            4: "Salva i Rapporti",
            5: "Salva i Pazienti",
            6: "Salva la Squadra",
            7: "Salva le Prescrizioni",
          },
          exportBtn: "Esporta",
          saveCopy: "Scarica copia",
        },
        netoyerCard: {
          title: "Pulizia dei file indesiderati sul server",
          notoyerText: "Il volume totale dei file è:",
          netoyerBtn: "Pulisci il server",
        },
      },
      sysncroPage: {
        title: "Tabelle aggiornate",
        savingModal: "Salvataggio",
        mainCard: {
          topText: "Fai clic sul pulsante per aggiornare i tuoi dati",
          bottomText:
            "Quando aggiorni i dati salvati sul server internet, puoi anche utilizzare l'applicazione in modalità offline, resta sempre aggiornato",
          btnText: "Aggiorna",
        },
        itemsList: {
          1: "Appuntamenti",
          2: "Sessioni",
          3: "Rapporti",
          4: "Pazienti",
          5: "Squadra",
          6: "Prescrizioni",
        },
      },
      documentPage: {
        itemsList: {
          1: "Appuntamenti",
          2: "Sessioni",
          3: "Rapporti",
          4: "Pazienti",
          5: "Squadra",
          6: "Prescrizioni",
        },
        savModal: {
          title: "Servizio post-vendita",
          inputPlch: "Fai qui la tua domanda",
        },
      },
    },
    menuTabs: {
      mainPage: {
        LinksCardItems: {
          rapportNum: {
            title: "Rapporto",
            desc: "Nuovo",
          },
          seanceNum: {
            title: "Sessione",
            desc: "Nuovo",
          },
          patientNum: {
            title: "Paziente",
            desc: "Aggiungi",
          },
          ordonanceNum: {
            title: "Prescrizione",
            desc: "Nuovo",
          },
          equipeNum: {
            title: "Squadra",
            desc: "Presente",
          },
        },
        evolutionCard: {
          title: "Evoluzione delle Sessioni",
        },
        tabsCard: {
          seance: {
            tabName: "Sessioni",
            cardTitle: "Distribuzione delle Sessioni",
          },
          rdv: {
            tabName: "Appuntamenti",
            cardTitle: "Distribuzione degli Appuntamenti",
          },
          patient: {
            tabName: "Paziente",
            cardTitle: "Distribuzione dei Pazienti",
          },
        },
      },
      rdvPage: {
        calendarLink: "Calendario",
        tabsTtems: {
          attent: "In attesa",
          seen: "Visto",
          accepte: "Accettato",
          refuse: "Rifiutato",
          retarde: "Ritardato",
          redirecte: "Ridirezionato",
          termine: "Terminato",
        },
        infoBtn: "Info",
        tableHeaderItems:
          "'*','ID','Paziente', 'Passato il','Volume','Tempo','Stato','Registrato?','Vedi'",
        calendarCardData: {
          searchCardTitle: "Ricerca...",
          searchCardBtn: "Cerca",
          rdvToday: "Appuntamenti di oggi",
        },
        rdvInfoCardData: {
          title: "Appuntamento",
          controlBtnCard: {
            title: "Controllo",
            rejectBtn: "Annulla",
            acceptBtn: "Accetta",
            retardBtn: "Ritarda",
            redirectBtn: "Ridireziona",
            saveBtn: "Salva seduta",
            termineBtn: "Termina",
            nonSeenBtn: "Segna come non visto",
          },
          infoReqCard: {
            title: "Informazioni sull'appuntamento",
            nameOfUser: "Nome e cognome",
            wantedDate: "Data desiderata",
            wantedTime: "Ora desiderata",
            reqDay: "Giorno passato",
            comment: "Commento",
          },
          infoUserCard: {
            title: "Informazioni paziente",
            wantYouSaveThisUser: "",
            name: "Nome:",
            phone: "Telefono:",
            delag: "Delegato:",
            gouv: "Governo:",
            adress: "Indirizzo:",
            saveBtn: "Salva cliente",
            stateItem: {
              dejaEnreg: "Già registrato",
              nouveaux: "Nuovo paziente",
            },
            statItemNames: {
              rdv: "Appuntamenti",
              ordonnace: "Ricette",
              seances: "Sedute",
            },
            addUserCard: {
              title: "Vuoi salvare questo paziente?",
            },
          },
        },
      },
      seancePage: {
        tarifName: "Tariffa",
        infoBtn: "Informazioni",
        maladieStateItems: {
          Bonne: "In Buone Condizioni",
          Malade: "Malato",
          Reanimation: "In Rianimazione",
          Palliatifs: "In Cure Palliative",
          Quarantaine: "In Quarantena",
          Observation: "In Osservazione",
        },
        seanceInfo: {
          tabsName: {
            diagnostique: "Diagnosi",
            analyse: "Analisi",
            ordonance: "Prescrizione",
            info: "Informazioni",
          },
          analyseList: "Elenco delle Analisi",
          ordonanceLit: "Elenco dei Medicamenti",
          ordonanceTable: {
            No: "N°",
            Designiation: "Designazione",
            Dosage: "Dosaggio",
            Forme: "Forma",
            Presentation: "Presentazione",
          },
          infoTable: {
            date: "Data e Ora",
            maladie: "Malattia",
            degree: "Grado della Malattia",
            description: "Descrizione della Malattia",
          },
          controlBtnCard: {
            title: "Controllo",
            modifier: "Modifica",
            supprimer: "Elimina",
            imprimer: "Stampa Prescrizione",
            suppModal: {
              voulezVous: "Vuoi davvero eliminare questa seduta?",
              suppBtn: "Elimina",
            },
          },
        },
        addSeanceInfo: {
          tabsName: {
            selectPat: "Seleziona Paziente",
            diagnostique: "Inserisci Diagnosi",
            analyse: "Esegui Analisi",
            ordonance: "Scrivi Prescrizione",
            terminer: "Termina il Risultato",
          },
          selectTabsData: {
            patientCardTitle: "Paziente",
            btnsText: {
              patientUID: "UID Paziente",
              scanRDV: "Scansiona Appuntamento",
            },
            patientInfo: {
              Nom: "Nome",
              phone: "Telefono",
              adress: "Indirizzo ",
              nbSeance: "Numero di Sedute",
              etatSnit: "Stato di Salute:",
            },
            leftTabs: {
              seance: "Sedute ",
              rdv: "Appuntamenti",
              ordonance: "Prescrizione",
              tableHearder: "'*','ID ','Data','Ora','Informazioni'",
            },
          },
          diagnosqtiqueTabsData: {
            title: "Diagnosi",
          },
          analyseTabsData: {
            title: "Aggiungi Grandezza",
            grandeurPlch: "Grandezza",
            valeurPlch: "Valore",
            btnText: "Aggiungi",
            listeText: "Elenco delle Analisi",
          },
          ordonanceTabsData: {
            title: "Aggiungi Articolo",
            addPlch: "Inserisci Medicamento",
            code: "Codice a Barre :",
            Nom: "Nome :",
            Dosage: "Dosaggio :",
            Forme: "Forma :",
            Presentation: "Presentazione :",
            modePlch: "Modalità di Utilizzo",
            btnText: "Aggiungi",
            listeText: "Elenco delle Analisi",
          },
          terminerTabsData: {
            maladie: " Malattia",
            resultat: "Risultato: Malattia",
            resultatPlch: "Malattia",
            date: "Data ",
            degre: "Grado di Pericolo",
            genre: "Genere della Seduta",
            pasGenre: {
              one: "Nessun Genere Salvato",
              two: "Clicca Qui",
              three: "Per Aggiungerlo ",
            },
            saveBtn: "Salva",
            printBtn: "Stampa Prescrizione",
          },
        },
        editSeance: {
          editBtn: "Modifica",
          pasDordo: "Nessuna Prescrizione",
          editOrdonance: "Puoi modificare la prescrizione qui:",
        },
        resumerCard: {
          cardTitle: "Inserisci un Periodo",
          rechercheBtn: "Ricerca",
          tableHeader:
            "'*','Paziente','Malattia','Grado','Giorno','Ora','Visualizza'",
        },
        tarifCard: {
          cardTitle: "Aggiungi Tariffa :",
          Nom: "Nome :",
          Description: "Descrizione:",
          Tarif: "Tariffa:",
          addBtn: "Salva",
          infoBtn: "Informazioni",
        },
        tarifInfoCard: {
          prixText: "PREZZO",
          tarifText: "TARIFFA",
          leftTab: {
            seance: "Seduta",
            modifier: "Modifica",
          },
          seanceTableH: "'ID','Membro','Data','Ora','Visualizza'",
          modifierCard: {
            nom: "Nome",
            tarif: "Tariffa",
            descr: "Descrizione",
            modifierBtn: "Modifica",
          },
        },
      },
      patientPage: {
        assuranceText: "Assicurazione",
        infoText: "Informazioni",
        addPatient: {
          nomEtPrenon: "Nome e Cognome",
          naissance: "Data di Nascita",
          phoneNum: "Telefono",
          location: "Posizione",
          gouv: "Seleziona Regione",
          deleg: "Seleziona Dipartimento",
          adresse: "Indirizzo",
          saveBtn: "Salva",
          rechercheAbyedh: {
            tabsNames: {
              enter: "Inserisci",
              scan: "Scansiona",
            },
            title: "Ricerca Nel Database Abyedh",
            btnText: "Ricerca",
            clicToScan: "Clicca per scannerizzare",
          },
        },
        patientInfo: {
          mainCardText: {
            verification: "Verifica",
            telephone: "Telefono",
          },
          TabsCardText: {
            seance: "Sessioni",
            ordonance: "Prescrizione",
            rdv: "Appuntamento",
            modifier: "Modifica",
            verifier: "Verifica",
            supprimer: "Elimina",
          },
          ordoTableaHeders: "'ID','Cliente','Data','Ora','Visualizza'",
          rdvTableaHeders: "'ID','Cliente','Data','Ora','Totale','Visualizza'",
          editCard: {
            nomEtPrenon: "Nome e Cognome:",
            naissance: "Data di Nascita",
            phoneNum: "Telefono",
            location: "Posizione",
            gouv: "Seleziona Regione",
            deleg: "Seleziona Dipartimento",
            adresse: "Indirizzo",
            editBtn: "Modifica",
          },
          verificationCard: {
            isVerifier: "Questo Paziente è Verificato",
            searchInAbyedhDB: "Ricerca Nel Database Abyedh",
            nom: "Nome",
            phone: "Telefono:",
            gouv: "Regione:",
            deleg: "Dipartimento:",
            verifBtn: "Verifica",
            searchBtn: "Ricerca",
          },
          deleteCard: {
            mainTitle: "Vuoi Davvero Eliminare Questo Paziente?",
            alertText: "Quando Elimini un Paziente",
            conscOne: "Il Paziente non sarà più visibile nel ramo 'Pazienti'",
            conscTwo:
              "Tutti i dati collegati a questo Paziente potrebbero danneggiarsi",
            conscThree:
              "Non sarai in grado di creare fatture o ordini con questo Paziente",
            deleteBtn: "Sì, Elimina",
          },
        },
      },
      ordonancePage: {
        StockText: "Magazzino",
        InfoBtn: "Info",
        ordoState: {
          terminer: "Terminato",
          annule: "Annullato",
          enCours: "In corso",
          indefinie: "Indefinito",
        },
        addOrdoPage: {
          listeText: "Elenco degli Analisi",
          tabsText: {
            enter: "Inserire",
            dateClient: "Data e Clienti",
            save: "Salva",
          },
          enterCard: {
            title: "Aggiungi articolo",
            addPlch: "Inserisci Medicinale",
            code: "Codice a barre:",
            Nom: "Nome:",
            Dosage: "Dosaggio:",
            Forme: "Forma:",
            Presentation: "Presentazione:",
            modePlch: "Modalità d'uso",
            btnText: "Aggiungi",
          },
          dateClientCard: {
            title: "Data e Cliente",
            nomClientText: "Nome:",
            adresseClientText: "Indirizzo:",
          },
          finisCard: {
            titleText: "Pulsanti",
            saveBtn: "Salva",
            printBnt: "Stampa",
          },
        },
        ordoInfoPage: {
          titleText: "RICETTA",
          ordoId: "ID Ricetta:",
          ordoCode: "Codice Ricetta:",
          patientText: "PAZIENTE:",
          dateText: "Data:",
          TimeText: "Ora:",
          tableHeader: {
            No: "No",
            Designiation: "Designazione",
            Dosage: "Dosaggio",
            Forme: "Forma",
            Presentation: "Presentazione",
          },
          controlBtns: {
            title: "Controllo",
            printBtn: "Stampa",
            editBtn: "Modifica",
            deleteBtn: "Elimina",
            seeSeance: "Visualizza Sessione",
          },
        },
        modifierOrdoPage: {
          editBtn: "Modifica",
        },
        stockCard: {
          addCard: {
            title: "Aggiungi Nuovo Medicinale",
            enregistrerBtn: "Salva",
            fermerBtn: "Chiudi",
          },
          infoCard: {
            tabsText: {
              info: "Informazioni",
              modifier: "Modifica",
              supprimer: "Elimina",
            },
            cardData: {
              dosage: "Dosaggio",
              presentaion: "Presentazione",
            },
            alertTer:
              "Non è possibile modificare o eliminare questo medicinale perché è un medicinale pubblico.",
            deleteCard: {
              mainTitle: "Vuoi davvero eliminare questo medicinale?",
              alertText: "Quando elimini un medicinale",
              conscOne:
                'Il medicinale non sarà più visibile nel ramo "Medicinali".',
              conscTwo:
                "Tutti i dati associati a questo medicinale potrebbero essere danneggiati.",
              conscThree:
                "Non sarai in grado di passare fatture o ordini con questo medicinale.",
              deleteBtn: "Sì, elimina",
            },
          },
        },
      },
      rapportPage: {
        infoBtn: "Informazioni",
        addRapport: {
          tabsText: {
            contenue: "Contenuto",
            terminer: "Termina",
          },
          rptPlch: "Scrivi qui il tuo rapporto |",
          terminerCard: {
            Titre: "Titolo",
            Sujet: "Argomento",
            Date: "Data",
            Genre: "Genere",
            genreListe: {
              1: "Medicina generale",
              2: "Ospedalizzazione",
              3: "Emergenza",
              4: "Consulenza",
              5: "Valutazione",
              6: "Diagnosi",
              7: "Seguito",
              8: "Ricerca medica",
              9: "Caso clinico",
              10: "Medico-legale",
              11: "Autopsia",
              12: "Sanità pubblica",
              13: "Altro",
            },
            saveBtn: "Salva",
            printBtn: "Stampa rapporto",
          },
        },
        rapportInfo: {
          titleText: "RAPPORTO",
          rapportId: "ID RAPPORTO :",
          rapportTitre: "TITOLO :",
          sujetText: "ARGOMENTO :",
          dateText: "Data :",
          GenreText: "GENERE :",
          controlCard: {
            title: "Controllo",
            editBtn: "Modifica",
            printBtn: "Stampa",
            deleteBtn: "Elimina",
          },
        },
        editRapport: {
          editBtn: "Modifica",
        },
      },
      teamPage: {
        interfaceLinkText: "Interfaccia RDV",
        infoBtn: "Informazioni",
        addTeamPage: {
          cin: "Carta CIN:",
          nomEtPrenom: "Nome e cognome:",
          phone: "Telefono:",
          poste: "Posizione:",
          adresse: "Indirizzo:",
          saveBtn: "Salva",
          rechercheAbyedh: {
            tabsNames: {
              enter: "Inserisci",
              scan: "Scansiona",
            },
            title: "Ricerca nel database Abyedh",
            btnText: "Ricerca",
            clicToScan: "Clicca per scansione",
          },
        },
        addPoste: {
          emptyListeText: "Aggiungi posizioni a destra",
          currency: "D.T",
          cardTitle: "Aggiungi posizione",
          poste: "Posizione",
          salaire: "Stipendio",
          experience: "Esperienza richiesta",
          saveBtn: "Salva",
          modifierTitle: "Modifica posizione",
          modifierBtn: "Modifica",
          supprimerModal: {
            title: "Elimina famiglia",
            confirmText: "Vuoi davvero eliminare questa famiglia?",
            nom: "Posizione",
            desc: "Descrizione",
            deleteBtn: "Elimina posizione",
          },
        },
        anavcePage: {
          selectMembre: "Seleziona membro",
          membrePlch: "Inserisci membro",
          montant: "Importo",
          montantPlch: "Valore",
          addBtn: "Aggiungi",
          tableaHeader: "'*','Nome','Giorno', 'Valore','X'",
          deleteModal: {
            title: "Elimina anticipo",
            confText: "Vuoi davvero eliminare questo anticipo?",
            valeur: " Valore:",
            membre: " Membro:",
            confirmBtn: "Elimina",
          },
        },
        presencePage: {
          selectMembre: "Seleziona membro",
          membrePlch: "Inserisci membro",
          genre: "Seleziona genere",
          presence: "Presenza",
          absance: "Assenza",
          date: "Data",
          addBtn: "Aggiungi",
        },
        teamInfoPage: {
          mainCardText: {
            verification: "Verifica",
            telephone: "Telefono",
            notVerifier: "Non verificato",
          },
          TabsCardText: {
            presence: "Presenza",
            avance: "Anticipi",
            caissePWD: "Cassa PWD",
            modifier: "Modifica",
            verifier: "Verifica",
            supprimer: "Elimina",
          },
          presenceTableaHeders: "'ID','Cliente','Giorno','Ora','Visualizza'",
          avanceTableaHeders:
            "'ID','Cliente','Giorno','Ora','Totale','Visualizza'",
          editCard: {
            cin: "Carta CIN",
            nomEtPrenon: "Nome e cognome:",
            phoneNum: "Telefono ",
            poste: "Posizione:",
            location: "Geolocalizzazione ",
            gouv: "Seleziona regione",
            deleg: "Seleziona dipartimento",
            adresse: "Indirizzo",
            editBtn: "Modifica",
          },
          caissePWDData: {
            identifiant: "Identificativo:",
            pwd: "Modalità password:",
            smartID: "ID intelligente ",
            editBtn: "Modifica",
          },
          verificationCard: {
            isVerifier: "Questo membro è verificato",
            searchInAbyedhDB: "Ricerca nel database Abyedh",
            nom: "Nome",
            phone: "Telefono:",
            gouv: "Gouv:",
            deleg: "Deleg:",
            verifBtn: "Verifica",
            searchBtn: "Ricerca",
          },
          deleteCard: {
            mainTitle: "Vuoi davvero eliminare questo membro?",
            alertText: "Quando elimini un membro",
            conscOne: 'Il membro non sarà visibile nel ramo "Membri"',
            conscTwo:
              "Tutti i dati relativi a questo membro potrebbero danneggiarsi",
            conscThree:
              "Altrimenti, non potrai fare fatture o ordini con questo membro",
            deleteBtn: "Sì, elimina",
          },
        },
      },
    },
    TableHead: {
      seance:
        "'*','ID','Paziente','Malattia','Grado','Giorno','Ora','Analisi','Prescrizione','Visualizza'",
      seance:
        "'*','ID','Paziente','Malattia','Grado','Giorno','Ora','Analisi','Prescrizione','Visualizza'",
      facture:
        "'*','ID','Cassa','Cliente','Giorno','Ora','Totale','Stato','Visualizza'",
      ordonance:
        "'*','ID','Paziente','Data','Ora','Sessione?','Stato','Visualizza'",
      seances: "'*','ID','Cassa','Cliente','Giorno','Ora','Stato','Visualizza'",
      request: "'*','ID','Cliente','Data','Tabella','Stato','X','Visualizza'",
      reservation:
        "'*','ID','Paziente','Passato il','Volume','Ora','Stato','Registrato?','Visualizza'",
      menu: "'*','Codice','Nome','Genere','Costo','Prezzo di vendita','X','Visualizza'",
      stock: "'*','Codice','Nome','Genere','Tariffa','Visualizza'",
      camion:
        "'*','Camion','Targa','Autista','Fondo','Ricetta','X','Visualizza'",
      camionStock: "'Codice','Nome','Genere','Scorta','Prezzo','Visualizza'",
      camionFacture: "'ID','Cliente','Giorno','Totale','Visualizza'",
      camionFond: "'ID','Data','Totale','SDF','SCF','Visualizza'",
      client:
        "'*','Nome','Nascita','Telefono','Posizione','Indirizzo','Visualizza'",
      clientCommande:
        "'ID','Passato il','Volume','Totale','Stato','Visualizza'",
      clientFacture: "'ID','Cliente','Giorno','Totale','Visualizza'",
      clientFactureC: "'ID','Cliente','Giorno','Totale','Visualizza'",
      team: "'*','Nome','Telefono','CIN','Iniziato','Posizione','Visualizza'",
      medicammentPage:
        "'*','Codice','Genere','Nome','Dosaggio','Forma','Presentazione','Classe','Visualizza'",
      fournisseur:
        "'*','MF','Nome','Telefono','Indirizzo','Genere','Giorno','Visualizza'",
    },
    BreadCrumb: {
      menuAddPlat: {
        1: "Menu",
        2: "Aggiungi Piatto",
      },
      menuFamille: {
        1: "Menu",
        2: "Famiglie",
      },
      platInfo: {
        1: "Menu",
        2: "Informazioni",
      },
      forfraitInfo: {
        1: "Tariffa",
        2: "Informazioni",
      },
      stockAddArticle: {
        1: "Magazzino",
        2: "Aggiungi Articolo",
      },
      stockFamille: {
        1: "Magazzino",
        2: "Famiglie",
      },
      stockBE: {
        1: "Magazzino",
        2: "Buono d'Entrata",
      },
      stockBS: {
        1: "Magazzino",
        2: "Buono di Uscita",
      },
      stockInfo: {
        1: "Magazzino",
        2: "Informazioni",
      },
      factureAjouter: {
        1: "Sessione",
        2: "Aggiungi Sessione",
      },
      factureInfo: {
        1: "Sessione",
        2: "Info",
      },
      factureEdit: {
        1: "Rapporto",
        2: "Modifica",
      },
      factureResumer: {
        1: "Sessione",
        2: "Riepilogo",
      },
      CamionAdd: {
        1: "Camion",
        2: "Aggiungi Camion",
      },
      CamionAddFond: {
        1: "Camion",
        2: "Aggiungi Fondo",
      },
      CamionFondInfo: {
        1: "Camion",
        2: "Info",
        3: "Fondo",
        4: "Vedi",
      },
      CamionEditFond: {
        1: "Camion",
        2: "Info",
        3: "Fondo",
        4: "Modifica",
      },
      CamionInv: {
        1: "Camion",
        2: "Inventario Camion",
      },
      CamionInfo: {
        1: "Camion",
        2: "Info",
      },
      CamionArticleInfo: {
        1: "Camion",
        2: "Info",
        3: "Articolo",
      },
      CamionFactureInfo: {
        1: "Camion",
        2: "Info",
        3: "Sessione",
        4: "Vedi",
      },
      ClientAdd: {
        1: "Paziente",
        2: "Aggiungi Paziente",
      },
      ClientInfo: {
        1: "Cliente",
        2: "Info",
      },
      ClientMap: {
        1: "Cliente",
        2: "Mappa",
      },
      ClientRegion: {
        1: "Cliente",
        2: "Regioni",
      },
      RequestInfo: {
        1: "Appuntamento",
        2: "Info",
      },
      RequestCalendar: {
        1: "Appuntamento",
        2: "Calendario",
      },
      RequestCompte: {
        1: "Ordini",
        2: "Conti",
      },
      RequestReg: {
        1: "Ordini",
        2: "Raggruppamento",
      },
      FournisseurAdd: {
        1: "Fornitore",
        2: "Raggruppamento",
      },
      FournisseurCalendar: {
        1: "Fornitore",
        2: "Raggruppamento",
      },
      FournisseurSearch: {
        1: "Fornitore",
        2: "Raggruppamento",
      },
      FournisseurInfo: {
        1: "Fornitore",
        2: "Raggruppamento",
      },
      TeamAdd: {
        1: "Squadra",
        2: "Aggiungi",
      },
      TeamInfo: {
        1: "Squadra",
        2: "Info",
      },
      TeamPoste: {
        1: "Squadra",
        2: "Ruoli",
      },
      TeamDemande: {
        1: "Squadra",
        2: "Richiesta Lavoro",
      },
    },
    SubNavs: {
      request: {
        1: "Accettato",
        2: "Rifiutato",
        3: "In attesa",
      },
      camion: {
        1: "Aggiungi prescrizione",
      },
      Menu: {
        1: "Nuovo rapporto",
      },
      Stock: {
        1: "Nuova prescrizione",
        2: "Famiglie di piatti",
        3: "Entrata merce",
        4: "Uscita merce",
      },
      facture: {
        1: "Nuova sessione",
        2: "Sommario",
      },
      client: {
        1: "Nuovo paziente",
      },
      Commande: {
        1: "Conti",
        2: "Calendario",
      },
      Fournisseur: {
        1: "Nuovo fornitore",
        2: "Calendario",
        3: "Ricerca",
      },
      Equipe: {
        1: "Nuovo membro",
        2: "Posizione",
        3: "Avanzato",
        4: "Presenza",
      },
    },
  },
};
export default ItaliaTrans;
