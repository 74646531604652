import React, {useEffect,useState}  from 'react';
import GConf from '../../../AssetsM/generalConf';
import SubNav from '../../../AssetsM/Cards/subNav';
import { _ } from "gridjs-react";
import axios from 'axios';
import { Fade } from 'react-reveal';
import SKLT from '../../../AssetsM/Cards/usedSlk';
import TableGrid from '../../../AssetsM/Cards/tableGrid';
import TableImage from '../../../AssetsM/Cards/tableImg';
import GoBtn from '../../../AssetsM/Cards/goBtn';
import { toast } from 'react-toastify';
import { Button , Icon, Input, Loader, Modal, Select} from 'semantic-ui-react';
import { useNavigate} from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import OneGConf from '../Assets/OneGConf';
import BackCard from '../Assets/Cards/backCard';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'


const RenouvellmentCard = ({
    RenouvellerAbonnemtFunc,
    renouvellmmentData,
    setRnouvellmmentData,
    loaderState,
  }) => {
    const SaisonChoise = [
      { id: 1, value: "2022", text: "2022" },
      { id: 2, value: "2023", text: "2023" },
      { id: 3, value: "2024", text: "2024" },
      { id: 4, value: "2025", text: "2025" },
      { id: 5, value: "2026", text: "2026" },
      { id: 6, value: "2027", text: "2027" },
      { id: 7, value: "2028", text: "2028" },
      { id: 8, value: "2029", text: "2029" },
      { id: 9, value: "2030", text: "2030" },
      { id: 10, value: "2031", text: "2031" },
      { id: 11, value: "2032", text: "2032" },
      { id: 12, value: "2033", text: "2033" },
      { id: 13, value: "2034", text: "2034" },
    ];
    return (
      <>
        <h5>
          Le Renouvellement Prend les meme donneé de cette abonnemment mais change
          seulmment la saisson et le jour de départ :{" "}
        </h5>
        <div className="row">
          <div className="col-6">
            <h5 className="mb-0 mt-2 text-secondary "> Saison </h5>
            <Select
              placeholder="Saisson"
              options={SaisonChoise}
              className="w-100 shadow-sm rounded mb-3"
              value={renouvellmmentData.AB_Saisson}
              onChange={(e, data) =>
                setRnouvellmmentData({
                  ...renouvellmmentData,
                  AB_Saisson: data.value,
                })
              }
            />
          </div>
          <div className="col-6">
            <h5 className="mb-0 mt-2 text-secondary ">Depart Le </h5>
            <Input
              icon="calendar"
              type="date"
              placeholder="Camion"
              iconPosition="left"
              fluid
              className="mb-1 shadow-sm"
              value={
                new Date(renouvellmmentData.AB_Depart_Date)
                  .toISOString()
                  .split("T")[0]
              }
              onChange={(e) =>
                setRnouvellmmentData({
                  ...renouvellmmentData,
                  AB_Depart_Date: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="text-end mb-5 mt-3">
          <Button
            onClick={RenouvellerAbonnemtFunc}
            className="text-end rounded-pill bg-system-btn"
            positive
          >
            {" "}
            <Icon name="edit" /> Renouveller{" "}
            <Loader
              inverted
              active={loaderState}
              inline
              size="tiny"
              className="ms-2 text-danger"
            />
          </Button>
        </div>
      </>
    );
  };

function FacturePage() {
    /*#########################[Const]##################################*/
    const navigate = useNavigate();
    const [facturesList, setFactureList] = useState([SKLT.TableSlt]); 
    let Offline = JSON.parse(localStorage.getItem(`${OneGConf.forPID.PID}_Offline`));
    const [modalS, setModalS] = useState(false)
    const [selectedArticle, setSelectedArticle] = useState([])
    const [renouvellmmentData, setRnouvellmmentData] = useState({ AB_Depart_Date: new Date().toISOString().split("T")[0], });
    const [loaderState, setLS] = useState(false);
    const [abonnemmentData, setAbonnemmentData] = useState({});

    /*#########################[UseEffect]##################################*/
     useEffect(() => {
        axios.post(`${GConf.ApiLink}/abonnement`, {
            PID : OneGConf.forPID.PID,
        })
        .then(function (response) {
 
            let factureListContainer = []
            response.data.map( (getData) => factureListContainer.push([
            _(<TableImage image='abonnemment.png' />),
            getData.AB_ID,
            getData.ME_Name,
            getData.F_Name,
            new Date(getData.AB_Depart_Date).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' ),
            getData.AB_Saisson,
            getData.Tarif,
            _(<StateCard status={CheckPaymmentOfMonth(getData.AB_Paymment)} />),
            _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ () => OpenRenouvellemntModal(getData, getData.AB_ID)}>  Ren   </Button>)
            ],))
            setFactureList(factureListContainer)
        }).catch((error) => {
            if(error.request) {
              toast.error(<><div><h5>Probleme de Connextion</h5> Les Donnée importeé sont les ancien donneé</div></>, GConf.TostInternetGonf) 
               
            }
          });
    }, [])
    
    
    /*#########################[Function]##################################*/
    const NavigateFunction = (link) => {  navigate(link) }
    const OpenRenouvellemntModal = (event,selected) =>{
        setAbonnemmentData(event)
        setModalS(true)
    }
    const StateCard = ({ status }) => {
        const StateCard = (props) =>{ return <span className={`badge bg-${props.color}`}> {props.text} </span>}
        const statusCard = React.useCallback(() => {
          switch(status) {
            case 'Payee': return <StateCard color='success' text='Payeé' />;  
            case 'NonPayee': return <StateCard color='danger' text='Non Payeé' /> ;
            case 'EnAttent': return <StateCard color='secondary' text='En Court' /> ;
            default:  return <StateCard color='warninf' text='Indefinie' />;    
          }
        }, [status]);
      
        return (
          <div className="container">
            {statusCard()}
          </div>
        );
    };

    const CheckPaymmentOfMonth = (value) =>{
        let searchForMonth = JSON.parse(value).find((data) => data.mois == (new Date()).getMonth() + 1)
        if (searchForMonth) {
            return 'Payee'
        } else {
            return 'NonPayee'
        }
    }
    const RenouvellerAbonnemtFunc = (event) => {
        setLS(true);
        axios
          .post(`${GConf.ApiLink}/abonnement/renouveller`, {
            PID: OneGConf.forPID.PID,
            abonnemmentData: abonnemmentData,
            renouvellmmentData: renouvellmmentData,
          })
          .then(function (response) {
            console.log(response.data);
            if (response.data.affectedRows) {
              toast.success("Abonnemment Renouveller !", GConf.TostSuucessGonf);
              setLS(false);
            } else {
              toast.error("Erreur esseyez de nouveaux", GConf.TostSuucessGonf);
              setLS(false);
            }
          })
          .catch((error) => {
            if (error.request) {
              toast.error(
                <>
                  <div>
                    <h5>Probleme de Connextion</h5> Impossible de modifier le Plat{" "}
                  </div>
                </>,
                GConf.TostInternetGonf
              );
              setLS(false);
            }
          });
    };
    const OnKeyPressFunc = (e) => {
        if (
          !(
            (e.charCode >= 65 && e.charCode <= 90) ||
            (e.charCode >= 97 && e.charCode <= 122) ||
            (e.charCode >= 48 && e.charCode <= 57) ||
            e.charCode == 42 ||
            e.charCode == 32 ||
            e.charCode == 47
          )
        ) {
          e.preventDefault();
        }
      };

    /*#########################[Card]##################################*/
    const SDF = (props)=>{
      return(<>
         <div className='text-center'><span className={`bi bi-${props.state == "true" ? 'check-circle-fill text-success': 'x-circle-fill text-danger'}`}></span> </div>
      </>)
    }
    
    const MainSubNavCard = (props) =>{
        return(<>
           <NavLink exact='true' to={`/S/${props.link}`} className='card card-body mb-1 rounded-pill shadow-sm d-inline-block ' >
            <h4 style={{color : GConf.themeColor}}> <spn className={`bi bi-${props.icon} me-1 `}></spn>{props.text}</h4>
          </NavLink>
        </>) 
    }

    return (<>
        <div className={`${OneGConf.themeMode == 'dark' ? 'bg-dark-theme-2' : '' }`} style={{height: '100vh', overflow: 'scroll'}}>
            <BackCard data={OneGConf.backCard.rt}/>
            <br />
            <div className='container'>
                <br />
                <TableGrid tableData={facturesList} columns={GConf.TableHead.abonnemment} />
            </div>
        </div>
        <BottomSheet expandOnContentDrag open={modalS}  onDismiss={() => setModalS(!modalS)}  >
                <div className='card-body'>
                    <RenouvellmentCard
                        OnKeyPressFunc={OnKeyPressFunc}
                        renouvellmmentData={renouvellmmentData}
                        setRnouvellmmentData={setRnouvellmmentData}
                        RenouvellerAbonnemtFunc={RenouvellerAbonnemtFunc}
                        loaderState={loaderState}
                    />
                
                </div>
        </BottomSheet>

        
    </>);
}

export default FacturePage;